import * as ActionTypes from './actionTypes'
import { createAction } from 'redux-actions'
import request from '../../http/request'

export const getRefList = params => {
  return async dispatch => {
    const response = await request.get('/casi-web/cscLeadTime/list', params)
    if (response && response.data && response.data.success) {
      // console.log('response.data.data', response.data.data)
      await dispatch(createAction(ActionTypes.GET_REFLIST)(response.data.data || {} || []))
    }
  }
}
