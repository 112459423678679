export const SET_SPIN_LOADING = 'SET_SPIN_LOADING'
export const SET_USER_INFO = 'SET_USER_INFO'
export const GET_USER_PERMISSION = 'GET_USER_PERMISSION'
export const GET_PERMISSION = 'GET_PERMISSION'
export const SET_ROUTER_CONFIG = 'SET_ROUTER_CONFIG'

export const GET_USERLIST = 'GET_USERLIST'
export const GET_ROLELIST = 'GET_ROLELIST'
export const SET_CURRENTROLES = 'SET_CURRENTROLES'
export const GET_DICTLIST = 'GET_DICTLIST'
export const GET_REFLIST = 'GET_REFLIST'
export const GET_ACTUALLIST = 'GET_ACTUALLIST'
export const GET_EXPECTEDLLIST = 'GET_EXPECTEDLLIST'
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION'
export const SET_ROLES = 'SET_ROLES'
export const GET_CSCCODELIST = 'GET_CSCCODELIST'
export const GET_LOCATIONLIST = 'GET_LOCATIONLIST'
export const GET_ACTUAL_LOCATIONLIST = 'GET_ACTUAL_LOCATIONLIST'
export const GET_ACTUALSTATISTICS = 'GET_ACTUALSTATISTICS'
export const GET_EXPECTSTATISTICS = 'GET_EXPECTSTATISTICS'

export const RESET_AUTH = 'RESET_AUTH'
