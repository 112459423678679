import React, { Component } from 'react'
import { Row, Col, Input, Tree, Button, message } from 'antd'
import { navigate } from '../../../utils/miscUtil'
import _ from 'lodash'
import { generateStructuredPermissions, getTreePermissions, updatableRoles } from '../../../utils/permission'
import * as ActionCreators from '../../../store/actions'
import { connect } from 'react-redux'
const { TreeNode } = Tree
class RoleCreate extends Component {
  constructor(props) {
    super(props)
    this.navigate = navigate.bind(this)
    this.state = {
      title: '',
      role: {
        permissions: []
      },
      currentRole: {}
    }
  }
  componentDidMount() {
    this.setState({ title: this.props.match.params.roleId ? 'Update Role' : 'Create Role' })
    if (this.props.match.params.roleId) {
      this.props.getCurrentRole(this.props.match.params.roleId).then(res => {
        this.setState({ currentRole: res })
      })
      // only call getRoles when roles hasn't been fetched, to help enhance performance
      this.props.roles.length === 0 && this.props.getRoles()
    } else {
      this.setState({
        currentRole: {
          roleName: '',
          permissions: []
        }
      })
    }
  }
  horizontalPermissions() {
    // partition permission tree to two groups
    const groupedTree = _.partition(
      generateStructuredPermissions(this.props.sysPermission.filter(item => item.code !== 'super-permission')),
      p => p.parentId == null && p.children
    )
    // flat each item in first group to an array
    return _.flatMap(groupedTree, array =>
      array.some(p => p.parentId == null && p.children) ? array.map(p => [p]) : [array]
    )
  }
  renderTree = permissionTree => (
    <Tree
      checkable
      expandedKeys={permissionTree.filter(p => p.parentId == null && p.children).map(p => p.code)}
      onCheck={checkedPermissions => this.onCheck(checkedPermissions, getTreePermissions(permissionTree))}
      checkedKeys={this.permissionsOnTree(permissionTree)}
    >
      {this.renderTreeNodes(permissionTree)}
    </Tree>
  )
  onCheck = (checkedPermissions, treePermissions) => {
    const permissionsOnOtherTree = _.difference(this.state.currentRole.permissions, treePermissions)
    this.setState({
      currentRole: {
        ...this.state.currentRole,
        permissions: [...permissionsOnOtherTree, ...checkedPermissions]
      }
    })
  }
  permissionsOnTree = permissionTree => {
    const treePermissions = getTreePermissions(permissionTree)
    return _.intersection(treePermissions, this.state.currentRole.permissions)
  }
  renderTreeNodes = permissionTree => {
    return permissionTree.map(item => {
      if (item.children) {
        return (
          <TreeNode
            disabled={!this.grantablePermissions().includes(item.code)}
            title={item.name}
            key={item.code}
            selectable={false}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return (
        <TreeNode
          disabled={!this.grantablePermissions().includes(item.code)}
          title={item.name}
          key={item.code}
          selectable={false}
        />
      )
    })
  }
  grantablePermissions() {
    const permission = _.uniq((this.props.permission || []).map(p => p.code))
    const sysPermission = _.uniq((this.props.sysPermission || []).map(p => p.code))
    if (_.intersection(['super-permission', 'user-manage', 'role-manage'], permission).length === 0) {
      return []
    } else {
      return sysPermission.filter(
        p =>
          !(
            p === 'super-permission' ||
            (['user-management', 'user-manage', 'role-manage', 'store-management', 'system-management'].includes(p) &&
              !permission.includes('super-permission'))
          )
      )
    }
  }
  canUpdateRole(roleId) {
    return (
      !this.props.match.params.roleId ||
      updatableRoles(this.props.permission, this.props.roles).some(role => role.id === roleId)
    )
  }
  saveCurrentRole = async () => {
    if (!this.state.currentRole.roleName) {
      message.warning('Please input the role name')
    } else if (this.state.currentRole.permissions.length === 0) {
      message.warning('Please select the access permission')
    } else {
      const result = await this.props.saveRole(this.state.currentRole)
      if (result) {
        message.success('Role saved successfully.')
        this.navigate('/user/role')
      }
    }
  }
  render() {
    const horizontalPermissions = this.horizontalPermissions()
    return (
      <div className="content-container-inside">
        <div className="title">{this.state.title}</div>
        <div className="form-container">
          <Row type="flex" justify="start" align="middle" className="form-item">
            <Col span={4} className="label">
              <span className="required">*</span>&nbsp;&nbsp;Role Name
            </Col>
            <Col span={6}>
              <Input
                value={this.state.currentRole.roleName}
                onChange={e =>
                  this.setState({
                    currentRole: { ...this.state.currentRole, roleName: e.target.value }
                  })
                }
              ></Input>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="top" className="form-item">
            <Col span={4} className="label">
              <span className="required">*</span>&nbsp;&nbsp;Access Permission
            </Col>
            <Col span={20}>
              <Row type="flex" justify="space-between">
                {horizontalPermissions.map((permissionTree, index) => (
                  <Col key={index}>{this.renderTree(permissionTree)}</Col>
                ))}
              </Row>
            </Col>
          </Row>
          {this.canUpdateRole(this.state.currentRole.id) && (
            <Row type="flex" justify="center" align="middle" className="form-item actions">
              <Button onClick={() => this.navigate('/user/role')}>Cancel</Button>
              <Button type="primary" onClick={() => this.saveCurrentRole()}>
                Save
              </Button>
            </Row>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ common: { permission, sysPermission }, roles: { currentRole, roles } }) => {
  return { permission, sysPermission, currentRole, roles }
}

const mapDispatchToProps = dispatch => {
  return {
    saveUser: user => dispatch(ActionCreators.saveUser(user)),
    getCurrentRole: id => dispatch(ActionCreators.getCurrentRole(id)),
    getRoles: () => dispatch(ActionCreators.getRoles()),
    saveRole: role => dispatch(ActionCreators.saveRole(role))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleCreate)
