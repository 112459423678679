import Actual from '../containers/pages/order/Actual'
import Expected from '../containers/pages/order/Expected'
import Role from '../containers/pages/user/Role'
import RoleCreate from '../containers/pages/user/RoleCreate'
import User from '../containers/pages/user/User'
import UserCreate from '../containers/pages/user/UserCreate'
import CSCLeadTime from '../containers/pages/reference/CSCLeadTime'

const totalRouter = [
  {
    path: '/user',
    title: 'User Management',
    permission: ['user-management'],
    hidden: false,
    existFunctionChild: false,
    children: [
      {
        path: '/user',
        title: 'User Management',
        permission: ['user-manage'],
        component: User,
        hidden: false,
        existFunctionChild: true,
        children: [
          {
            path: '/add',
            title: 'Create User',
            permission: ['user-manage'],
            hidden: true,
            component: UserCreate
          },
          {
            path: '/:userId',
            title: 'Update User',
            permission: ['user-manage', 'super-permission'],
            hidden: true,
            component: UserCreate
          }
        ]
      },
      {
        path: '/role',
        title: 'Role Management',
        permission: ['role-manage'],
        component: Role,
        hidden: false,
        existFunctionChild: true,
        children: [
          {
            path: '/add',
            title: 'Create Role',
            permission: 'role-manage',
            hidden: true,
            component: RoleCreate
          },
          {
            path: '/:roleId',
            title: 'Update Role',
            permission: ['role-manage', 'super-permission'],
            hidden: true,
            component: RoleCreate
          }
        ]
      }
    ]
  },
  {
    path: '/order',
    title: 'Order Management',
    permission: ['order-management'],
    hidden: false,
    existFunctionChild: false,
    children: [
      {
        path: '/actual',
        title: 'Actual Receiving',
        permission: ['actual-receiving'],
        hidden: false,
        component: Actual
      },
      {
        path: '/expected',
        title: 'Expected Receiving',
        permission: ['expected-receiving'],
        hidden: false,
        component: Expected
      }
    ]
  },
  {
    path: '/reference',
    title: 'Reference',
    permission: ['reference-management'],
    hidden: false,
    existFunctionChild: false,
    children: [
      {
        path: '/csc',
        title: 'RSC Lead Time',
        permission: ['csc-lead-time'],
        hidden: false,
        component: CSCLeadTime
      }
    ]
  }
]

export const checkPermissionWithCurrentPath = (currentPath, routers) => {
  let pathes = currentPath.split('/')
  // console.log(currentPath)
  if (pathes.length == 2) {
    return false
  } else {
    if (pathes.length === 3) {
      let routerOne = '/' + pathes[1]
      let routerTwo = '/' + pathes[2]
      let res = false
      for (let i = 0; i < routers.length; i++) {
        let item = routers[i]
        if (item.path === routerOne && !item.hidden) {
          // console.log('一级成功', item.path, routerOne, item.hidden, '匹配 二级 ', routerTwo)
          for (let j = 0; j < item.children.length; j++) {
            let subitem = item.children[j]
            if (subitem.path === routerTwo && !subitem.hidde) {
              return true
            }
          }
        }
      }
      return res
    } else {
      return false
    }
  }
}

export const hasVisibleChildren = item => {
  return item.children && item.children.some(child => !child.hidden)
}

export const getSubMenuPath = () => {
  let submenupath = []
  totalRouter.map((item, index) => {
    if (hasVisibleChildren(item)) {
      item.children.map(subItem => {
        submenupath.push(item.path + subItem.path)
      })
    }
  })
  return submenupath
}

const checkPermission = (item, permission) => {
  for (let i = 0; i < permission.length; i++) {
    const permiss = permission[i]
    if (item.permission.indexOf(permiss.code) !== -1) {
      item.hidden = false
      if (hasVisibleChildren(item) && !item.existFunctionChild) {
        item.children.map((subItem, subIndex) => {
          checkPermission(subItem, permission)
        })
      }
      break
    } else {
      item.hidden = true
    }
  }
  return item
}

const withPermission = (totalRouter, permission) => {
  if (permission.length === 0) {
    return []
  }
  let checkRouter = []
  totalRouter.map((item, index) => {
    checkPermission(item, permission)
    checkRouter.push(item)
  })
  // console.log('After Permission Router-------', checkRouter)
  return checkRouter
}

export const getRouterConfig = permission => {
  return withPermission(totalRouter, permission)
}
