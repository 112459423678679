import React, { Component } from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../store/actions'

class CSCLeadTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      criteria: {
        pageNum: 1,
        pageSize: 10
      }
    }
  }
  getColumns = () => {
    return [
      {
        title: 'RSC Name',
        dataIndex: 'cscName',
        key: 'cscName'
      },
      {
        title: 'RSC Code',
        dataIndex: 'cscCode',
        key: 'cscCode'
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city'
      },
      {
        title: 'LeadTime(days)',
        dataIndex: 'leadTimeDays',
        key: 'leadTimeDays'
      }
    ]
  }
  componentDidMount() {
    this.props.getRefList(this.state.criteria)
  }
  render() {
    // console.log(this.props.refList)
    return (
      <div className="content-container">
        <div className="table-container table-container-csc">
          <Table
            size="middle"
            columns={this.getColumns()}
            pagination={false}
            // pagination={{
            //   current: this.state.pageNum,
            //   pageSize: this.state.pageSize,
            //   size: 'small',
            //   pageSizeOptions: ['10', '20', '30', '40'],
            //   total: this.props.refList.length,
            //   showTotal: total => `Total ${total} items`,
            //   showSizeChanger: true
            // }}
            dataSource={this.props.refList.map((refs, key) => {
              return { ...refs, key }
            })}
          ></Table>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ common: { spinLoading }, refs: { refList } }) => {
  return { spinLoading, refList }
}

const mapDispatchToProps = dispatch => {
  return {
    setSpinLoading: status => dispatch(ActionCreators.setSpinLoading(status)),
    getRefList: params => dispatch(ActionCreators.getRefList(params))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CSCLeadTime)
