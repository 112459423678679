import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../store/actions'
import { Row, Col, Input, Select, Button, Table, DatePicker, Modal, message } from 'antd'
import Total from '../../../components/total/Total'
import ExpectedDetail from './ExpectedDetail'
import _ from 'lodash'
import { postDownload } from '../../../utils/permission'
import moment from 'moment'
const { Option } = Select
class Expected extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewVisible: false,
      criteria: {
        pageNum: 1,
        pageSize: 10,
        shipmentStatus: 'IN_TRANSIT'
        // beginDate: moment().subtract(1, 'days'),
        // endDate: moment()
      },
      viewRecord: {},
      totalVisible: false,
      expectedList: {
        records: [],
        total: 0
      },
      expectStatistics: {
        totalCartonItems: 0,
        totalCartons: 0,
        totalShipments: 0
      }
    }
  }

  getColumns = () => {
    return [
      {
        title: 'No.',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <span>{index + 1}</span>
      },
      {
        title: 'RSC',
        dataIndex: 'cscCode',
        key: 'cscCode',
        width: 80,
        render: text => <span>{text || '-'}</span>
      },
      {
        title: 'From Location',
        dataIndex: 'fromLocation',
        key: 'fromLocation',
        width: 130,
        filters:
          this.props.locationList.fromLocation &&
          this.props.locationList.fromLocation.map(value => ({ text: value, value: value })),
        render: text => <span>{text || '-'}</span>
      },
      {
        title: 'Target Location',
        dataIndex: 'targetLocation',
        key: 'targetLocation',
        width: 130,
        filters:
          this.props.locationList.targetLocation &&
          this.props.locationList.targetLocation.map(value => ({ text: value, value: value }))
      },
      {
        title: 'Shipment Number',
        dataIndex: 'shipmentNumber',
        key: 'shipmentNumber',
        width: 140
      },
      {
        title: 'Delivery Number',
        dataIndex: 'deliveryNumber',
        key: 'deliveryNumber',
        width: 140
      },
      {
        title: 'Carton Number',
        dataIndex: 'cartonNumber',
        key: 'cartonNumber',
        width: 150
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        key: 'upc',
        width: 120
      },
      {
        title: 'QTY',
        dataIndex: 'qty',
        key: 'qty',
        width: 100
      },
      {
        title: 'Ship Date',
        dataIndex: 'shipDate',
        key: 'shipDate',
        width: 120
      },
      {
        title: 'ETA Date',
        dataIndex: 'etaDate',
        key: 'etaDate',
        width: 120
      },
      {
        title: 'Operation',
        key: 'operations',
        // width: 120,
        render: (text, record) => (
          <span
            className="nike-link"
            onClick={() => {
              this.viewClickEvent(record)
            }}
          >
            View
          </span>
        )
      }
    ]
  }

  viewClickEvent = record => {
    this.setState({ viewVisible: true, viewRecord: record })
  }

  searchClickEvent = () => {
    if (this.state.criteria.beginDate || this.state.criteria.endDate) {
      if (!this.state.criteria.cscCode) {
        message.warning('Please select RSC')
      } else {
        this.serchList()
      }
    } else {
      this.serchList()
    }
  }

  serchList = () => {
    let criteria = {
      ...this.state.criteria,
      beginDate: (this.state.criteria.beginDate && moment(this.state.criteria.beginDate).format('YYYY-MM-DD')) || '',
      endDate: (this.state.criteria.endDate && moment(this.state.criteria.endDate).format('YYYY-MM-DD')) || ''
    }
    this.props.getExpectedList(criteria).then(res => {
      if (res) {
        this.setState({ expectedList: res })
      }
    })
    this.checkTotalVisible(criteria)
  }

  checkTotalVisible = criteria => {
    if (
      criteria.cscCode ||
      criteria.shipmentNumber ||
      criteria.cartonNumber ||
      criteria.beginDate ||
      criteria.endDate
    ) {
      let params = _.omit(criteria, ['pageNum', 'pageSize'])
      this.setState({ totalVisible: true })
      this.props.getExpectReceiveSta(params).then(res => {
        if (res) {
          this.setState({ expectStatistics: res })
        }
      })
    } else {
      this.setState({ totalVisible: false })
    }
  }

  onClickTotalNumber = type => {
    console.log(type)
  }

  handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...this.state.criteria,
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      ..._.mapValues(filters, values => _.join(values, ','))
    }
    let criteria = {
      ...params,
      beginDate: (this.state.criteria.beginDate && moment(this.state.criteria.beginDate).format('YYYY-MM-DD')) || '',
      endDate: (this.state.criteria.endDate && moment(this.state.criteria.endDate).format('YYYY-MM-DD')) || ''
    }
    this.setState({ criteria: { ...params } })
    this.props.getExpectedList(criteria).then(res => {
      if (res) {
        this.setState({ expectedList: res })
      }
    })
    this.checkTotalVisible(criteria)
    var dom = document.querySelector('.ant-table-body')
    dom.scrollTop = 0
  }

  componentDidMount() {
    this.props.getCscCodeList()
    // this.setState({ criteria: { ...this.state.criteria, cscCode: this.props.cscCodeList[0].cscCode } })
    let criteria = {
      ...this.state.criteria,
      beginDate: (this.state.criteria.beginDate && moment(this.state.criteria.beginDate).format('YYYY-MM-DD')) || '',
      endDate: (this.state.criteria.endDate && moment(this.state.criteria.endDate).format('YYYY-MM-DD')) || ''
    }
    // this.props.getExpectedList(criteria)
    this.props.getTargetSourceLocationList()
    this.checkTotalVisible(criteria)
  }
  async exportOrder(params) {
    const url = `${process.env.REACT_APP_API_URL}/api-proxy/casi-web/carton-item/downloadExpectedReceiving`
    if (this.state.criteria.beginDate && this.state.criteria.endDate && !this.state.criteria.cscCode) {
      message.warning('Please select RSC')
    } else if (
      !this.state.criteria.shipmentNumber &&
      !this.state.criteria.cartonNumber &&
      (!this.state.criteria.beginDate || !this.state.criteria.endDate)
    ) {
      message.warning('Please select ETA date')
    } else {
      await postDownload(url, params)
    }
  }

  handelCscChange = value => {
    this.setState({ criteria: { ...this.state.criteria, cscCode: value } })
    this.props.getTargetSourceLocationList(value)
  }

  render() {
    const statistics = this.state.expectStatistics
    return (
      <div className="content-container">
        <Row className="search-bar" type="flex" align="middle">
          <Col span={3} className="text-right">
            <Row type="flex" align="middle">
              <Col span={8} className="search-title">
                RSC
              </Col>
              <Col span={16}>
                <Select
                  className="search-content"
                  placeholder="All"
                  allowClear={true}
                  value={this.state.criteria.cscCode}
                  onChange={this.handelCscChange}
                >
                  {this.props.cscCodeList &&
                    this.props.cscCodeList.map(list => (
                      <Option key={list.cscCode} value={list.cscCode}>
                        {list.cscName}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={3} className="text-right">
            <Row type="flex" align="middle">
              <Col span={12} className="search-title">
                Shipment No.
              </Col>
              <Col span={12}>
                <Input
                  className="search-content"
                  allowClear={true}
                  value={this.state.criteria.shipmentNumber}
                  onChange={e =>
                    this.setState({ criteria: { ...this.state.criteria, shipmentNumber: e.target.value } })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={3} className="text-right">
            <Row type="flex" align="middle">
              <Col span={9} className="search-title">
                Carton No.
              </Col>
              <Col span={14}>
                <Input
                  className="search-content"
                  allowClear={true}
                  value={this.state.criteria.cartonNumber}
                  onChange={e => this.setState({ criteria: { ...this.state.criteria, cartonNumber: e.target.value } })}
                />
              </Col>
            </Row>
          </Col>
          <Col span={4} className="text-right">
            <Row type="flex" align="middle">
              <Col span={8} className="search-title">
                Shipment Status
              </Col>
              <Col span={14}>
                <Select
                  className="search-content"
                  placeholder="All"
                  value={this.state.criteria.shipmentStatus}
                  onChange={value => this.setState({ criteria: { ...this.state.criteria, shipmentStatus: value } })}
                >
                  <Option key="IN_TRANSIT" value="IN_TRANSIT">
                    InTransit
                  </Option>
                  <Option key="RECEIVED" value="RECEIVED">
                    Received
                  </Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={4} className="text-right">
            <Row type="flex" align="middle">
              <Col className="search-title" span={7}>
                ETA From
              </Col>
              <Col span={16}>
                <DatePicker
                  // style={{ width: '150px' }}
                  format="YYYY-MM-DD"
                  // showTime
                  // allowClear={false}
                  // disabledDate={beginDate =>
                  //   (beginDate &&
                  //     this.state.criteria.endDate &&
                  //     this.state.criteria.endDate.diff(beginDate, 'days') >= 7) ||
                  //   beginDate.isAfter(this.state.criteria.endDate, 'day')
                  // }
                  value={this.state.criteria.beginDate}
                  onChange={beginDate =>
                    this.state.criteria.endDate &&
                    (this.state.criteria.endDate.isBefore(beginDate, 'day') ||
                      this.state.criteria.endDate.diff(beginDate, 'days') >= 7)
                      ? this.setState({ criteria: { ...this.state.criteria, beginDate, endDate: null } })
                      : this.setState({ criteria: { ...this.state.criteria, beginDate } })
                  }
                ></DatePicker>
              </Col>
            </Row>
          </Col>
          <Col span={4} className="text-right">
            <Row type="flex" align="middle">
              <Col className="search-title" span={7}>
                ETA Till
              </Col>
              <Col span={16}>
                <DatePicker
                  // style={{ width: '150px' }}
                  format="YYYY-MM-DD"
                  // allowClear={false}
                  disabledDate={endDate =>
                    (endDate &&
                      this.state.criteria.beginDate &&
                      endDate.diff(this.state.criteria.beginDate, 'days') >= 7) ||
                    endDate.isBefore(this.state.criteria.beginDate, 'day')
                  }
                  value={this.state.criteria.endDate}
                  onChange={endDate => this.setState({ criteria: { ...this.state.criteria, endDate } })}
                ></DatePicker>
              </Col>
            </Row>
          </Col>
          <Col span={2} className="text-center">
            <Row>
              <Col span={10} className="text-right">
                <Button type="primary" onClick={this.searchClickEvent}>
                  Search
                </Button>
              </Col>
              <Col span={10} className="text-left" style={{ paddingLeft: '30px' }}>
                <Button
                  type="primary"
                  onClick={() =>
                    this.exportOrder(
                      _.omit(
                        {
                          ...this.state.criteria,
                          beginDate:
                            (this.state.criteria.beginDate && this.state.criteria.beginDate.format('YYYY-MM-DD')) || '',
                          endDate:
                            (this.state.criteria.endDate && this.state.criteria.endDate.format('YYYY-MM-DD')) || ''
                        },
                        ['pageNum', 'pageSize']
                      )
                    )
                  }
                >
                  Export
                </Button>
                <a id="downloadDiv" style={{ display: 'none' }}></a>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.state.totalVisible && (
          <Total
            numbers={[statistics.totalShipments, statistics.totalCartons, statistics.totalCartonItems]}
            onClickTotalNumber={this.onClickTotalNumber}
          ></Total>
        )}
        <Row>
          <div
            className={
              this.state.totalVisible
                ? 'table-container table-container-expect'
                : 'table-container table-container-expect-before'
            }
          >
            <Table
              size="middle"
              // bordered
              scroll={{ y: `calc(100vh - ${this.state.totalVisible ? '376px' : '335px'})`, x: 1400 }}
              columns={this.getColumns()}
              dataSource={
                this.state.expectedList.records &&
                this.state.expectedList.records.map((expectedItem, key) => {
                  return { ...expectedItem, key }
                })
              }
              pagination={{
                current: this.state.criteria.pageNum,
                pageSize: this.state.criteria.pageSize,
                pageSizeOptions: ['10', '20', '50', '100'],
                size: 'small',
                total: this.state.expectedList.total,
                showTotal: total => `Total ${total} items`,
                showSizeChanger: true
              }}
              onChange={this.handleTableChange}
            />
          </div>
        </Row>
        <Modal
          title={'UPC - ' + this.state.viewRecord.upc + ' in Carton - ' + this.state.viewRecord.cartonNumber}
          visible={this.state.viewVisible}
          onCancel={() => this.setState({ viewVisible: false })}
          maskClosable={false}
          width="800px"
          className="ctr-modal"
          footer={[
            <Button key="back" onClick={() => this.setState({ viewVisible: false })}>
              Close
            </Button>
          ]}
        >
          <ExpectedDetail viewRecord={this.state.viewRecord}></ExpectedDetail>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({
  common: { spinLoading },
  expects: { expectedList, expectStatistics, locationList },
  actuals: { cscCodeList }
}) => {
  return { spinLoading, expectedList, cscCodeList, locationList, expectStatistics }
}

const mapDispatchToProps = dispatch => {
  return {
    setSpinLoading: status => dispatch(ActionCreators.setSpinLoading(status)),
    getCscCodeList: () => dispatch(ActionCreators.getCscCodeList()),
    getExpectedList: params => dispatch(ActionCreators.getExpectedList(params)),
    getTargetSourceLocationList: cscCode => dispatch(ActionCreators.getTargetSourceLocationList(cscCode)),
    getExpectReceiveSta: params => dispatch(ActionCreators.getExpectReceiveSta(params))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Expected)
