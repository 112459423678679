import React, { Component } from 'react'
import { Row, Col, Icon } from 'antd'
import './Total.less'
class Total extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="total-content">
        <Row>
          <Col span={4}>
            <Icon type="bar-chart" style={{ marginRight: '10px' }} />
            Total Shipments
            <a onClick={() => this.props.onClickTotalNumber(0)} className="total-number">
              {this.props.numbers[0]}
            </a>
          </Col>
          <Col span={4}>
            <Icon type="appstore" style={{ marginRight: '10px' }} />
            Total Cartons
            <a onClick={() => this.props.onClickTotalNumber(1)} className="total-number">
              {this.props.numbers[1]}
            </a>
          </Col>
          <Col span={4}>
            <Icon type="build" style={{ marginRight: '10px' }} />
            Total Cartons Items
            <a onClick={() => this.props.onClickTotalNumber(2)} className="total-number">
              {this.props.numbers[2]}
            </a>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Total
