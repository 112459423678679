import { combineReducers } from 'redux'
import { commonReducer } from './common.reducer'
import { dictReducer } from './dict.reducer'
import { refReducer } from './reference.reducer'
import { actualReducer } from './actual.reducer'
import { expectedReducer } from './expected.reducer'
import { userReducer } from './user.reducer'
import { roleReducer } from './role.reducer'
const rootReducer = combineReducers({
  common: commonReducer,
  dict: dictReducer,
  refs: refReducer,
  actuals: actualReducer,
  expects: expectedReducer,
  users: userReducer,
  roles: roleReducer
})

export default rootReducer
