import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../store/actions'
import { Row, Col, Input, Select, Button, Table, Divider, Modal, message } from 'antd'
import moment from 'moment'
import { navigate } from '../../../utils/miscUtil'
import { grantableRoles } from '../../../utils/permission'

const { Option } = Select

class User extends Component {
  constructor(props) {
    super(props)
    this.navigate = navigate.bind(this)
    this.state = {
      deleteUser: {},
      visible: false,
      criteria: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        roleId: ''
      }
    }
  }
  getColumns = () => {
    return [
      {
        title: 'No.',
        dataIndex: 'no',
        key: 'no',
        width: 80,
        render: (text, record, index) => <span>{index + 1}</span>
      },
      {
        title: 'Name',
        dataIndex: 'userName',
        key: 'userName',
        width: 160
      },
      {
        title: 'Access Role',
        dataIndex: 'roleName',
        key: 'roleName',
        width: 120,
        render: text => (text ? text : <span style={{ color: 'red' }}>Null</span>)
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        width: 140
      },
      {
        title: 'Create Date',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 170,
        render: text => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '')
      },
      {
        title: 'Operation',
        dataIndex: 'id',
        key: 'operation',
        render: (text, record) =>
          this.canOperator(record.roleId) && (
            <span>
              <a className="nike-link" onClick={() => this.gotoUpdate(text)}>
                Update
              </a>
              <Divider type="vertical" />
              <a className="nike-link" onClick={() => this.deleteUserRecord(record)}>
                Delete
              </a>
            </span>
          )
      }
    ]
  }
  handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...this.state.criteria,
      pageNum: pagination.current,
      pageSize: pagination.pageSize
    }
    this.setState({ criteria: { ...params } })
    this.props.getUserList(params)
  }
  handledeleteOk = () => {
    this.setState({
      deletevisible: false
    })
  }
  handledeleteCancel = () => {
    this.setState({
      deletevisible: false
    })
  }
  createClickEvent = () => {
    this.navigate('/user/user/add')
  }
  gotoUpdate = id => {
    this.navigate('/user/user/' + id)
  }
  deleteUserRecord = record => {
    this.setState({ visible: true, deleteUser: record })
  }
  searchClickEvent = () => {
    this.props.getUserList(this.state.criteria)
  }
  componentDidMount() {
    this.props.getRoles()
    const paramRoleId = new URLSearchParams(this.props.location.search).get('roleId')
    const roleId = paramRoleId ? parseInt(paramRoleId) : ''
    this.setState({ criteria: { ...this.state.criteria, roleId } }, () => this.props.getUserList(this.state.criteria))
  }
  async deleteUser(user) {
    const result = await this.props.deleteUser(user.id)
    if (result) {
      this.setState({ visible: false })
      message.success('User deleted successfully.')
      this.props.getUserList(this.state.criteria)
    }
  }
  canOperator(roleId) {
    // eslint-disable-next-line
    return !roleId || grantableRoles(this.props.permission, this.props.roles).some(role => role.id == roleId)
  }
  render() {
    return (
      <div className="content-container">
        <Row className="search-bar" type="flex" justify="space-between" align="middle">
          <Col span={8} className="text-right">
            <Row type="flex" align="middle">
              <Col className="search-title" span={9}>
                Name&nbsp;&nbsp;
              </Col>
              <Col span={8}>
                <Input
                  style={{ width: 200 }}
                  value={this.state.criteria.userName}
                  onChange={e =>
                    this.setState({
                      criteria: {
                        ...this.state.criteria,
                        userName: e.target.value
                      }
                    })
                  }
                ></Input>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="text-right">
            <Row type="flex" align="middle">
              <Col className="search-title" span={9}>
                Role&nbsp;&nbsp;
              </Col>
              <Col span={10}>
                <Select
                  style={{ width: 200 }}
                  allowClear={true}
                  value={this.state.criteria.roleId}
                  onChange={value =>
                    this.setState({
                      criteria: { ...this.state.criteria, roleId: value }
                    })
                  }
                >
                  {this.props.roles &&
                    this.props.roles.map(role => (
                      <Option key={role.id} value={role.id}>
                        {role.roleName}
                      </Option>
                    ))}
                  <Option key={-1} value={-1}>
                    <span style={{ color: 'red' }}>Null</span>
                  </Option>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="text-center">
            <Row>
              <Col span={10} className="text-right">
                <Button type="primary" onClick={this.searchClickEvent}>
                  Search
                </Button>
              </Col>
              <Col span={10} className="text-left" style={{ paddingLeft: '10px' }}>
                <Button type="primary" onClick={this.createClickEvent}>
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="table-container">
          <Table
            size="middle"
            // bordered
            scroll={{ y: `calc(100vh - 316px)`, x: 1140 }}
            columns={this.getColumns()}
            pagination={{
              current: this.state.criteria.pageNum,
              pageSize: this.state.criteria.pageSize,
              size: 'small',
              pageSizeOptions: ['10', '20', '30', '40'],
              total: this.props.userList.total,
              showTotal: total => `Total ${total} items`,
              showSizeChanger: true
            }}
            dataSource={
              this.props.userList.records &&
              this.props.userList.records.map((user, key) => {
                return { ...user, key }
              })
            }
            onChange={this.handleTableChange}
          ></Table>
        </div>
        <Modal
          title={['Delete User', `'${this.state.deleteUser.userName}'`].join(' - ')}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          cancelText="CLOSE"
          onOk={() => this.deleteUser(this.state.deleteUser)}
          okText="DELETE"
        >
          <h2 style={{ fontSize: 'larger', color: 'red', textAlign: 'center' }}>Reminder!</h2>
          <span>Are you sure to delete this user?</span>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ common: { spinLoading, permission }, users: { userList }, roles: { roles } }) => {
  return { spinLoading, userList, roles, permission }
}

const mapDispatchToProps = dispatch => {
  return {
    setSpinLoading: status => dispatch(ActionCreators.setSpinLoading(status)),
    getRoles: () => dispatch(ActionCreators.getRoles()),
    getUserList: params => dispatch(ActionCreators.getUserList(params)),
    deleteUser: id => dispatch(ActionCreators.deleteUser(id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(User)
