import * as ActionTypes from './actionTypes'
import { createAction } from 'redux-actions'
import request from '../../http/request'

export const getExpectedList = params => {
  return async dispatch => {
    const response = await request.post('/casi-web/carton-item/expectedReceiving', params)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_EXPECTEDLLIST)(response.data.data || {} || []))
      return response.data.data
    }
  }
}

export const getExpectReceiveSta = params => {
  return async dispatch => {
    const response = await request.post('/casi-web/carton-item/expectedReceivingStatistics', params)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_EXPECTSTATISTICS)(response.data.data || {} || []))
      return response.data.data
    }
  }
}

export const getTargetSourceLocationList = cscCode => {
  return async dispatch => {
    const response = await request.get(`/casi-web/carton-item/getTargetSourceLocationList?cscCode=${cscCode || ''}`)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_LOCATIONLIST)(response.data.data || {} || []))
    }
  }
}
