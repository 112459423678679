import * as ActionTypes from './actionTypes'
import { createAction } from 'redux-actions'
import request from '../../http/request'

export const getActualList = params => {
  return async dispatch => {
    const response = await request.post('/casi-web/csc-receiving/actualReceiving', params)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_ACTUALLIST)(response.data.data || {} || []))
    }
  }
}

export const getCscCodeList = () => {
  return async dispatch => {
    const response = await request.get('/casi-web/direct-store/getCscCodeList')
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_CSCCODELIST)(response.data.data || {} || []))
    }
  }
}

export const getActualTargetSourceLocationList = cscCode => {
  return async dispatch => {
    const response = await request.get(`/casi-web/csc-receiving/getTargetSourceLocationList?cscCode=${cscCode || ''}`)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_ACTUAL_LOCATIONLIST)(response.data.data || {} || []))
    }
  }
}

export const getActualReceiveSta = params => {
  return async dispatch => {
    const response = await request.post('/casi-web/csc-receiving/actualReceivingStatistics', params)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_ACTUALSTATISTICS)(response.data.data || {} || []))
    }
  }
}
