import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../store/actions'
import { Row, Col, Input, Select, Button, Table, DatePicker, Tag } from 'antd'
import { postDownload } from '../../../utils/permission'
import _ from 'lodash'
import { formatNumber } from '../../../utils/miscUtil'
import Total from '../../../components/total/Total'
import moment from 'moment'
const { Option } = Select

const getStatusColor = key => {
  switch (key) {
    case 'POSTED':
      return 'green'
    case 'RECEIVED':
      return 'blue'
    case 'IN_PROGRESS':
      return 'orange'
    default:
      return ''
  }
}

class Actual extends Component {
  constructor(props) {
    super(props)
    this.state = {
      criteria: {
        pageNum: 1,
        pageSize: 10,
        beginDate: moment().subtract(1, 'days'),
        endDate: moment()
      },
      totalVisible: false
    }
  }

  getColumns = () => {
    return [
      {
        title: 'No.',
        dataIndex: 'no',
        key: 'no',
        width: 50,
        render: (text, record, index) => <span>{index + 1}</span>
      },
      {
        title: 'RSC',
        dataIndex: 'cscCode',
        key: 'cscCode',
        width: 80
      },
      {
        title: 'From Location',
        dataIndex: 'sourceId',
        key: 'fromLocation',
        width: 120,
        filters:
          this.props.actualLocationList.fromLocation &&
          this.props.actualLocationList.fromLocation.map(value => ({ text: value, value: value }))
        // filterMultiple: false
      },
      {
        title: 'Target Location',
        dataIndex: 'storeId',
        key: 'targetLocation',
        width: 120,
        // filterMultiple: false,
        filters:
          this.props.actualLocationList.targetLocation &&
          this.props.actualLocationList.targetLocation.map(value => ({ text: value, value: value }))
      },
      {
        title: 'Shipment Number',
        dataIndex: 'ibOrderNumber',
        key: 'ibOrderNumber',
        width: 140,
        filterMultiple: false
        // filters: this.props.actualList.records.map(value => ({ text: value.target, value: value.target }))
      },
      {
        title: 'Carton Number',
        dataIndex: 'cartonNumber',
        key: 'cartonNumber',
        width: 150
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        key: 'upc',
        width: 130
      },
      {
        title: 'QTY',
        dataIndex: 'goodsReceiptUnits',
        key: 'goodsReceiptUnits',
        width: 80
      },
      {
        title: 'Order Status',
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        width: 100,
        render: (text, record) => {
          if (record.orderStatus) {
            return <Tag color={getStatusColor(record.orderStatus)}>{record.orderStatus}</Tag>
          }
        }
      },
      {
        title: 'Creation Time',
        dataIndex: 'createDate',
        key: 'createDate',
        width: 160,
        sorter: (a, b) => {}
        // width: 140,
        // sorter: (a, b) => (a.statusUpdateTime < b.statusUpdateTime ? 1 : -1)
      },
      {
        title: 'Status Update Time',
        dataIndex: 'statusUpdateTime',
        key: 'statusUpdateTime'
        // sorter: (a, b) => (a.statusUpdateTime < b.statusUpdateTime ? 1 : -1)
      }
    ]
  }

  searchClickEvent = () => {
    let criteria = {
      ...this.state.criteria,
      beginDate: (this.state.criteria.beginDate && moment(this.state.criteria.beginDate).format('YYYY-MM-DD')) || '',
      endDate: (this.state.criteria.endDate && moment(this.state.criteria.endDate).format('YYYY-MM-DD')) || ''
    }
    this.props.getActualList(criteria)
    this.checkTotalVisible(criteria)
  }

  checkTotalVisible = criteria => {
    if (
      criteria.cscCode ||
      criteria.shipmentNumber ||
      criteria.cartonNumber ||
      criteria.beginDate ||
      criteria.endDate
    ) {
      let params = _.omit(criteria, ['pageNum', 'pageSize'])
      this.setState({ totalVisible: true })
      this.props.getActualReceiveSta(params)
    } else {
      this.setState({ totalVisible: false })
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sortWay = sorter.order ? (sorter.order === 'descend' ? 'DESC' : 'ASC') : ''
    const params = {
      ...this.state.criteria,
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      ..._.mapValues(filters, values => _.join(values, ',')),
      createDateOrderBy: sortWay
    }
    let criteria = {
      ...params,
      beginDate: (this.state.criteria.beginDate && moment(this.state.criteria.beginDate).format('YYYY-MM-DD')) || '',
      endDate: (this.state.criteria.endDate && moment(this.state.criteria.endDate).format('YYYY-MM-DD')) || ''
    }
    this.setState({ criteria: { ...params } })
    this.props.getActualList(criteria)
    this.checkTotalVisible(criteria)
    var dom = document.querySelector('.ant-table-body')
    dom.scrollTop = 0
  }

  onClickTotalNumber = type => {
    console.log(type)
  }

  componentDidMount() {
    let criteria = {
      ...this.state.criteria,
      beginDate: (this.state.criteria.beginDate && moment(this.state.criteria.beginDate).format('YYYY-MM-DD')) || '',
      endDate: (this.state.criteria.endDate && moment(this.state.criteria.endDate).format('YYYY-MM-DD')) || ''
    }
    this.props.getActualList(criteria)
    this.props.getCscCodeList()
    this.props.getActualTargetSourceLocationList()
    this.checkTotalVisible(criteria)
  }

  async exportOrder(params) {
    const url = `${process.env.REACT_APP_API_URL}/api-proxy/casi-web/csc-receiving/downloadActualReceiving`
    await postDownload(url, params)
  }

  handelCscChange = value => {
    this.setState({ criteria: { ...this.state.criteria, cscCode: value } })
    this.props.getActualTargetSourceLocationList(value)
  }

  render() {
    const statistics = this.props.actualStatistics
    return (
      <div className="content-container">
        <Row className="search-bar" type="flex" align="middle">
          <Col span={3} className="text-right">
            <Row type="flex" align="middle">
              <Col span={8} className="search-title">
                RSC
              </Col>
              <Col span={16}>
                <Select
                  className="search-content"
                  placeholder="All"
                  allowClear={true}
                  value={this.state.criteria.cscCode}
                  onChange={this.handelCscChange}
                >
                  {this.props.cscCodeList &&
                    this.props.cscCodeList.map(list => (
                      <Option key={list.cscCode} value={list.cscCode}>
                        {list.cscName}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={4} className="text-right">
            <Row type="flex" align="middle">
              <Col span={12} className="search-title">
                Shipment No.
              </Col>
              <Col span={12}>
                <Input
                  className="search-content"
                  allowClear={true}
                  value={this.state.criteria.shipmentNumber}
                  onChange={e =>
                    this.setState({ criteria: { ...this.state.criteria, shipmentNumber: e.target.value } })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col span={4} className="text-right">
            <Row type="flex" align="middle">
              <Col span={10} className="search-title">
                Carton No.
              </Col>
              <Col span={14}>
                <Input
                  className="search-content"
                  allowClear={true}
                  value={this.state.criteria.cartonNumber}
                  onChange={e => this.setState({ criteria: { ...this.state.criteria, cartonNumber: e.target.value } })}
                />
              </Col>
            </Row>
          </Col>
          <Col span={5} className="text-right">
            <Row type="flex" align="middle">
              <Col className="search-title" span={8}>
                Date From
              </Col>
              <Col span={16}>
                <DatePicker
                  style={{ width: '160px' }}
                  format="YYYY-MM-DD"
                  value={this.state.criteria.beginDate}
                  // disabledDate={beginDate =>
                  //   (beginDate &&
                  //     this.state.criteria.endDate &&
                  //     this.state.criteria.endDate.diff(beginDate, 'days') >= 7) ||
                  //   beginDate.isAfter(this.state.criteria.endDate, 'day')
                  // }
                  onChange={beginDate =>
                    this.state.criteria.endDate &&
                    (this.state.criteria.endDate.isBefore(beginDate, 'day') ||
                      this.state.criteria.endDate.diff(beginDate, 'days') >= 7)
                      ? this.setState({ criteria: { ...this.state.criteria, beginDate, endDate: null } })
                      : this.setState({ criteria: { ...this.state.criteria, beginDate } })
                  }
                ></DatePicker>
              </Col>
            </Row>
          </Col>
          <Col span={5} className="text-right">
            <Row type="flex" align="middle">
              <Col className="search-title" span={7}>
                Date Till
              </Col>
              <Col span={16}>
                <DatePicker
                  style={{ width: '160px' }}
                  format="YYYY-MM-DD"
                  disabledDate={endDate =>
                    (endDate &&
                      this.state.criteria.beginDate &&
                      endDate.diff(this.state.criteria.beginDate, 'days') >= 7) ||
                    endDate.isBefore(this.state.criteria.beginDate, 'day')
                  }
                  value={this.state.criteria.endDate}
                  onChange={endDate => this.setState({ criteria: { ...this.state.criteria, endDate } })}
                ></DatePicker>
              </Col>
            </Row>
          </Col>
          <Col span={3} className="text-center">
            <Row>
              <Col span={10} className="text-right">
                <Button type="primary" onClick={this.searchClickEvent}>
                  Search
                </Button>
              </Col>
              <Col span={10} className="text-left" style={{ paddingLeft: '10px' }}>
                <Button
                  type="primary"
                  onClick={() =>
                    this.exportOrder(
                      _.omit(
                        {
                          ...this.state.criteria,
                          beginDate:
                            (this.state.criteria.beginDate && this.state.criteria.beginDate.format('YYYY-MM-DD')) || '',
                          endDate:
                            (this.state.criteria.endDate && this.state.criteria.endDate.format('YYYY-MM-DD')) || ''
                        },
                        ['pageNum', 'pageSize']
                      )
                    )
                  }
                >
                  Export
                </Button>
                <a id="downloadDiv" style={{ display: 'none' }}></a>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.state.totalVisible && (
          <Total
            numbers={[statistics.totalShipments, statistics.totalCartons, statistics.totalCartonItems]}
            onClickTotalNumber={this.onClickTotalNumber}
          ></Total>
        )}
        <Row>
          <div className={this.state.totalVisible ? 'table-container table-container-actual' : 'table-container'}>
            <Table
              size="middle"
              // bordered
              scroll={{
                y: `calc(100vh - ${this.state.totalVisible ? '355px' : '315px'})`,
                x: 1300
              }}
              columns={this.getColumns()}
              // scrollToFirstRowOnChange={true}
              dataSource={
                this.props.actualList.records &&
                this.props.actualList.records.map((actual, key) => {
                  return { ...actual, key }
                })
              }
              pagination={{
                current: this.state.criteria.pageNum,
                pageSize: this.state.criteria.pageSize,
                pageSizeOptions: ['10', '20', '50', '100'],
                size: 'small',
                total: this.props.actualList.total,
                showTotal: total => `Total ${formatNumber(total)} items`,
                showSizeChanger: true
              }}
              onChange={this.handleTableChange}
            />
          </div>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = ({
  common: { spinLoading },
  actuals: { actualList, cscCodeList, actualLocationList, actualStatistics }
}) => {
  return { spinLoading, actualList, cscCodeList, actualLocationList, actualStatistics }
}

const mapDispatchToProps = dispatch => {
  return {
    setSpinLoading: status => dispatch(ActionCreators.setSpinLoading(status)),
    getActualList: params => dispatch(ActionCreators.getActualList(params)),
    getCscCodeList: () => dispatch(ActionCreators.getCscCodeList()),
    getActualTargetSourceLocationList: cscCode => dispatch(ActionCreators.getActualTargetSourceLocationList(cscCode)),
    getActualReceiveSta: params => dispatch(ActionCreators.getActualReceiveSta(params))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Actual)
