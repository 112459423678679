import _ from 'lodash'
export const generateStructuredPermissions = (permissions = [], parentId = null) => {
  // eslint-disable-next-line
  const children = permissions.filter(p => p.parentId == parentId)
  return (
    children.length > 0 &&
    children.map(parent => ({ ...parent, children: generateStructuredPermissions(permissions, parent.id) }))
  )
}

export const getTreePermissions = permissionTree => {
  const treePermissions = (permissionTree || []).map(parent => [parent.code, ...getTreePermissions(parent.children)])
  return _.flatten(treePermissions)
}

export const postDownload = async (url, params) => {
  const request = {
    body: JSON.stringify(params),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }

  const response = await fetch(url, request)
  const filename = response.headers
    .get('content-disposition')
    .split(';')[1]
    .split('=')[1]
  const blob = await response.blob()

  const link = document.createElement('a')
  link.download = decodeURIComponent(filename)
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}

export const grantableRoles = (permissions, roles) => {
  const permission = _.uniq((permissions || []).map(p => p.code))
  if (_.intersection(['super-permission', 'user-manage', 'role-manage'], permission).length === 0) {
    return []
  } else {
    return roles.filter(
      role =>
        !(
          // role.permissions.includes('super-permission') ||
          (
            _.intersection(['super-permission', 'user-management', 'user-manage', 'role-manage'], role.permissions)
              .length > 0 && !permission.includes('super-permission')
          )
        )
    )
  }
}

export const updatableRoles = (permissions, roles) => {
  const permission = _.uniq((permissions || []).map(p => p.code))
  if (_.intersection(['super-permission', 'user-manage', 'role-manage'], permission).length === 0) {
    return []
  } else {
    return roles.filter(
      role =>
        !(
          role.permissions.includes('super-permission') ||
          (_.intersection(['user-management', 'user-manage', 'role-manage'], role.permissions).length > 0 &&
            !permission.includes('super-permission'))
        )
    )
  }
}
