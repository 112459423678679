import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
const initialState = {
  dictList: []
}
const dictReducer = handleActions(
  {
    [ActionTypes.GET_DICTLIST]: (state, action) => ({ ...state, dictList: action.payload })
  },
  initialState
)

export { dictReducer }
