const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || ''
const ISSUER = process.env.REACT_APP_OKTA_ISSUER || ''
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false
const REDIRECT_URI = `${window.location.origin}/authorization-code/callback`

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
  }
}
