import * as ActionTypes from './actionTypes'
import { createAction } from 'redux-actions'
import request from '../../http/request'

export const getRoleList = params => {
  return async dispatch => {
    const response = await request.get('/casi-web/roles', { params })
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_ROLELIST)(response.data.data || {} || []))
    }
  }
}

export const getRoles = () => {
  return async dispatch => {
    const response = await request.get('/casi-web/roles', { params: { pageNum: 1, pageSize: 1000 } })
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.SET_ROLES)((response.data.data || {}).records || []))
    }
  }
}

export const deleteRole = id => {
  return async dispatch => {
    const response = await request.get(`/casi-web/rolesDel/${id}`)
    if (response && response.data && response.data.success) {
      return true
    }
  }
}

export const getCurrentRole = id => {
  return async dispatch => {
    const response = await request.get(`/casi-web/roles/${id}`)
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.SET_CURRENTROLES)(response.data.data || {} || []))
      return response.data.data
    }
  }
}

export const saveRole = role => {
  return async dispatch => {
    if (role.id) {
      const response = await request.post(`/casi-web/rolesEdit/${role.id}`, role)
      if (response && response.data && response.data.success) {
        return true
      }
    } else {
      const response = await request.post('/casi-web/roles', role)
      if (response && response.data && response.data.success) {
        return true
      }
    }
  }
}
