import React, { Component } from 'react'
import { Form, Input, Select, Button, Row, message } from 'antd'
import { navigate } from '../../../utils/miscUtil'
import * as ActionCreators from '../../../store/actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import { grantableRoles } from '../../../utils/permission'
const FormItem = Form.Item
const Option = Select.Option

class UserCreateForm extends Component {
  constructor(props) {
    super(props)
    this.navigate = navigate.bind(this)
    this.state = {
      currentUser: {}
    }
  }
  componentDidMount() {
    if (this.props.match.params.userId) {
      this.props.getUserUpdateDetail(this.props.match.params.userId).then(res => {
        this.setState({ currentUser: res })
      })
    }
    this.props.getRoles()
  }
  canUpdateUser(roleId) {
    return (
      !this.props.match.params.userId ||
      !roleId ||
      grantableRoles(this.props.permission, this.props.roles).some(role => role.id === roleId)
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props
          .saveUser({
            id: this.state.currentUser.id,
            ...values
          })
          .then(res => {
            if (res) {
              message.success('User saved successfully.')
              this.navigate('/user/user')
            }
          })
      }
    })
  }

  grantableRoles() {
    const permission = _.uniq((this.props.permission || []).map(p => p.code))
    if (_.intersection(['super-permission', 'user-manage', 'role-manage'], permission).length === 0) {
      return []
    } else {
      return this.props.roles.filter(
        role =>
          !(
            // role.permissions.includes('super-permission') ||
            (
              _.intersection(
                [
                  'super-permission',
                  'user-management',
                  'user-manage',
                  'role-manage',
                  'store-management',
                  'system-management'
                ],
                role.permissions
              ).length > 0 && !permission.includes('super-permission')
            )
          )
      )
    }
  }
  render() {
    const grantableRoles = this.grantableRoles()
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    }
    return (
      <div className="content-container-inside">
        <div className="title">{this.props.match.params.userId ? 'Update User' : 'Create User'}</div>
        <div className="form-container" style={{ width: '60%', margin: '0 auto' }}>
          <Form onSubmit={this.handleSubmit}>
            <FormItem {...formItemLayout} label="User Name">
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: 'Please input the user name!' }],
                initialValue: this.state.currentUser.userName || ''
              })(<Input style={{ width: '100%' }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Nike Email Address">
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Please input the email!' },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  }
                ],
                normalize: (value, prevValue, allValues) => {
                  return (value || '').toLowerCase()
                },
                initialValue: this.state.currentUser.email || ''
              })(<Input style={{ width: '100%' }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Phone Number">
              {getFieldDecorator('phoneNumber', {
                initialValue: this.state.currentUser.phoneNumber || ''
              })(<Input style={{ width: '100%' }} />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Role">
              {getFieldDecorator('roleId', {
                rules: [{ required: true, message: 'Please choose the role!' }],
                initialValue: this.state.currentUser.roleId || ''
              })(
                <Select>
                  {grantableRoles.map(role => (
                    <Option value={role.id} key={role.id}>
                      {role.roleName}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
            {this.canUpdateUser(this.state.currentUser.roleId) && (
              <FormItem>
                <Row type="flex" justify="center" align="middle" className="form-item actions">
                  <Button
                    onClick={() => {
                      this.navigate('/user/user')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    OK
                  </Button>
                </Row>
              </FormItem>
            )}
          </Form>
        </div>
      </div>
    )
  }
}

const UserCreate = Form.create()(UserCreateForm)

const mapStateToProps = ({ common: { permission }, users: { userList }, roles: { roles } }) => {
  return { permission, userList, roles }
}

const mapDispatchToProps = dispatch => {
  return {
    saveUser: user => dispatch(ActionCreators.saveUser(user)),
    getUserUpdateDetail: id => dispatch(ActionCreators.getUserUpdateDetail(id)),
    getRoles: () => dispatch(ActionCreators.getRoles())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
