import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
const initialState = {
  refList: []
}
const refReducer = handleActions(
  {
    [ActionTypes.GET_REFLIST]: (state, action) => ({ ...state, refList: action.payload })
  },
  initialState
)

export { refReducer }
