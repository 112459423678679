import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Logo from '../../components/logo/Logo'
import { Row, Col, Icon, Modal } from 'antd'
// import { clearAuthentication } from '../../../utils/authHelp'

import './Header.less'
// const Search = Input.Search

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resetFormVisible: false
    }
  }

  showLogoutModal = () => {
    this.setState({ logoutVisible: true })
  }

  handleLogoutCancel = () => {
    this.setState({ logoutVisible: false })
  }

  handelLogoutOk = () => {
    this.setState({ logoutVisible: false })
    this.props.loggout()
  }

  renderUser() {
    return (
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <span style={{ marginRight: '20px' }}>{(this.props.userInfo && this.props.userInfo.email) || ''}</span>
        <span style={{ marginRight: '10px' }} onClick={() => this.showLogoutModal()}>
          Logout
        </span>
      </div>
    )
  }
  render() {
    // console.log('header',this.props)
    return (
      <nav className="gnav">
        <div className="gnav-bar">
          <Row type="flex" justify="space-between" align="middle">
            <Col span={8}>
              <Logo link="/" title="NIKE CASI - SIM Integration" />
            </Col>
            <Col span={8} style={{ color: 'white', textAlign: 'right' }}>
              {this.props.userInfo && this.renderUser()}
              <Icon
                type="question-circle"
                style={{
                  fontSize: '24px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginLeft: '0px'
                }}
              />
            </Col>
          </Row>
          <Modal
            title="Alert"
            visible={this.state.logoutVisible}
            onCancel={this.handleLogoutCancel}
            onOk={this.handelLogoutOk}
          >
            <p>Are you sure to logout system ?</p>
            <p>你确定要退出系统吗？</p>
          </Modal>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = ({ common: { userInfo } }) => {
  return { userInfo }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
