import moment from 'moment'
import _ from 'lodash'
export function navigate(to, state) {
  this.props.history.push(to, state)
}

export function preventDefaultEvent(e) {
  e.preventDefault()
}

export const formatNumber = value => {
  if (value) {
    return `${_.round(value, 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return 0
  }
}

export const toThousands = value => {
  if (value) {
    if (value >= 100 || value <= -100) {
      return `${_.round(value / 1000, 1)}k`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return formatNumber(value)
    }
  } else {
    return 0
  }
}

export const parseNumber = value => {
  return value && value.replace(/\s|(,*)/g, '')
}

export const getPeriod = (start, end) => {
  if (start && end) {
    const diffDays = (moment.isMoment(end) ? _.cloneDeep(end) : moment(end))
      .startOf('day')
      .diff((moment.isMoment(start) ? _.cloneDeep(start) : moment(start)).startOf('day'), 'days')
    if (diffDays <= 30) {
      // 1~31 daily
      return 'Daily'
    } else if (diffDays <= 179) {
      // 32~180 weekly
      return 'Weekly'
    } else {
      return 'Monthly' // 181~ monthly
    }
  }
  return ''
}

export const extendDuration = (from, to, fixedPeriod) => {
  const _from = _.cloneDeep(from)
  const _to = _.cloneDeep(to)
  const period = _.capitalize(fixedPeriod !== undefined ? fixedPeriod : getPeriod(_from, _to))
  let updatedTimes = {}
  switch (period) {
    case 'Monthly': {
      // convert start to the first day of this month, convert end to the last day of this month
      const startOfMonth = _from.startOf('month')
      const endOfMonth = _to.endOf('month')
      updatedTimes = {
        from: startOfMonth,
        to: endOfMonth
      }
      break
    }
    case 'Weekly': {
      // convert start to first day of this week, convert end to the last day of this week
      const startOfWeek = _from.startOf('isoWeek')
      const endOfWeek = _to.endOf('isoWeek')
      const newPeriod = getPeriod(startOfWeek, endOfWeek)
      // if the period between the new start/end day becomes 'Monthly'
      // convert start to the first day of this month, convert end to the last day of this month
      if (newPeriod === 'Monthly') {
        const startOfMonth = startOfWeek.startOf('month')
        const endOfMonth = endOfWeek.endOf('month')
        updatedTimes = {
          from: startOfMonth,
          to: endOfMonth
        }
      } else {
        updatedTimes = {
          from: startOfWeek,
          to: endOfWeek
        }
      }
      break
    }
    default:
      updatedTimes = {
        from: _from,
        to: _to
      }
      break
  }
  return updatedTimes
}

export const isWeekend = date => {
  if (date) {
    return date.isSame(_.cloneDeep(date).endOf('isoWeek'), 'day')
  }
  return false
}

export const isEndOfMonth = date => {
  if (date) {
    return date.isSame(_.cloneDeep(date).endOf('month'), 'day')
  }
  return false
}

export const isBeforeYesterday = date => {
  const today = moment().startOf('day')
  const diff = today.diff(moment(date).startOf('day'), 'days')
  return diff > 1
}

export const isYesterday = date => {
  if (date) {
    const today = moment().startOf('day')
    const diff = today.diff(moment(date).startOf('day'), 'days')
    return diff === 1
  } else {
    return false
  }
}

export const isToday = date => {
  if (date) {
    const today = moment().startOf('day')
    const diff = today.diff(moment(date).startOf('day'), 'days')
    return diff === 0
  } else {
    return false
  }
}

export const isWithinThreeDay = date => {
  const today = moment().startOf('day')
  const diff = today.diff(moment(date).startOf('day'), 'days')
  return diff < 2
}
export const isWithinFiveDay = date => {
  const today = moment().startOf('day')
  const diff = today.diff(moment(date).startOf('day'), 'days')
  return diff < 4
}
export const isWithinOneMonth = date => {
  const today = moment().startOf('day')
  const diff = today.diff(moment(date).startOf('day'), 'months')
  return diff <= 0
}

export const isWithinThreeMonth = date => {
  const today = moment().startOf('day')
  const diff = today.diff(moment(date).startOf('day'), 'months')
  return diff < 2
}
