import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
// import _ from 'lodash'
const initialState = {
  spinLoading: false,
  userInfo: {},
  permission: [],
  sysPermission: [],
  isResetAuth: false,
  routerConfig: []
}

const commonReducer = handleActions(
  {
    [ActionTypes.SET_SPIN_LOADING]: (state, action) => ({ ...state, spinLoading: action.payload }),
    [ActionTypes.SET_USER_INFO]: (state, action) => ({ ...state, userInfo: action.payload }),
    [ActionTypes.GET_USER_PERMISSION]: (state, action) => ({ ...state, permission: action.payload }),
    [ActionTypes.GET_PERMISSION]: (state, action) => ({ ...state, sysPermission: action.payload }),
    [ActionTypes.SET_ROUTER_CONFIG]: (state, action) => ({ ...state, routerConfig: action.payload }),
    [ActionTypes.RESET_AUTH]: (state, action) => ({ ...state, isResetAuth: action.payload })
  },
  initialState
)

export { commonReducer }
