const getAccessToken = () => {
  const storage = localStorage.getItem('okta-token-storage')
  const oktaStorage = JSON.parse(storage)
  const access_token = oktaStorage.accessToken.accessToken
  return access_token
}

const generateAuthHeader = () => {
  const storage = localStorage.getItem('okta-token-storage')
  const oktaStorage = JSON.parse(storage)
  const access_token = oktaStorage.accessToken.accessToken
  return {
    Authorization: access_token || '',
    AuthType: '0'
  }
}
export { generateAuthHeader, getAccessToken }
