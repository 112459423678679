import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
const initialState = {
  actualList: {},
  cscCodeList: [],
  actualStatistics: {},
  actualLocationList: {}
}
const actualReducer = handleActions(
  {
    [ActionTypes.GET_ACTUALLIST]: (state, action) => ({ ...state, actualList: action.payload }),
    [ActionTypes.GET_CSCCODELIST]: (state, action) => ({ ...state, cscCodeList: action.payload }),
    [ActionTypes.GET_ACTUAL_LOCATIONLIST]: (state, action) => ({ ...state, actualLocationList: action.payload }),
    [ActionTypes.GET_ACTUALSTATISTICS]: (state, action) => ({ ...state, actualStatistics: action.payload })
  },
  initialState
)

export { actualReducer }
