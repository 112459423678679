import React from 'react'
import { Route, useHistory, Switch, BrowserRouter as Router } from 'react-router-dom'
import { OktaAuth } from '@okta/okta-auth-js'
import { Security, LoginCallback } from '@okta/okta-react'

import MainLayout from './containers/MainLayout'
import Login from './containers/login/Login'

import './assets/css/ncss.en-us.css'
import './assets/css/glyphs.css'
import './App.less'

import config from './oktaConfig'

const oktaAuth = new OktaAuth(config.oidc)

const App = () => {
  const history = useHistory()
  const customAuthHandler = () => {
    history.push('/login')
  }
  return (
    <Router>
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler}>
        <Switch>
          <Route path="/login" exact component={Login}></Route>
          <Route path="/authorization-code/callback" component={LoginCallback}></Route>
          <Route path="/" component={MainLayout}></Route>
        </Switch>
      </Security>
    </Router>
  )
}

export default App
