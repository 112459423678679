import * as ActionTypes from './actionTypes'
import { createAction } from 'redux-actions'
import request from '../../http/request'

export const getUserList = params => {
  return async dispatch => {
    const response = await request.get('/casi-web/user', { params })
    if (response && response.data && response.data.success) {
      await dispatch(createAction(ActionTypes.GET_USERLIST)(response.data.data || {} || []))
    }
  }
}

export const saveUser = user => {
  return async dispatch => {
    if (user.id) {
      const response = await request.post(`/casi-web/userEdit/${user.id}`, user)
      if (response && response.data && response.data.success) {
        return true
      }
    } else {
      const response = await request.post('/casi-web/user', user)
      if (response && response.data && response.data.success) {
        return true
      }
    }
  }
}

export const deleteUser = id => {
  return async dispatch => {
    const response = await request.get(`/casi-web/userDel/${id}`)
    if (response && response.data && response.data.success) {
      return true
    }
  }
}

export const getUserUpdateDetail = id => {
  return async dispatch => {
    const response = await request.get(`/casi-web/user/${id}`)
    if (response && response.data && response.data.success) {
      return response.data.data
    }
  }
}
