import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
const initialState = {
  roles: [],
  roleList: {},
  currentRole: {}
}
const roleReducer = handleActions(
  {
    [ActionTypes.SET_ROLES]: (state, action) => ({ ...state, roles: action.payload }),
    [ActionTypes.GET_ROLELIST]: (state, action) => ({ ...state, roleList: action.payload }),
    [ActionTypes.SET_CURRENTROLES]: (state, action) => ({ ...state, currentRole: action.payload })
  },
  initialState
)

export { roleReducer }
