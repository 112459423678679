import * as ActionTypes from './actionTypes'
import { createAction } from 'redux-actions'
import request from '../../http/request'
export const setSpinLoading = status => {
  return dispatch => {
    dispatch(createAction(ActionTypes.SET_SPIN_LOADING)(status))
  }
}
export const setUserInfo = status => {
  return dispatch => {
    dispatch(createAction(ActionTypes.SET_USER_INFO)(status))
  }
}

export const setRouterConfig = status => {
  return dispatch => {
    dispatch(createAction(ActionTypes.SET_ROUTER_CONFIG)(status))
  }
}

export const getUserPermissions = () => {
  return async dispatch => {
    const response = await request.get('/casi-web/permission')
    if (response && response.data && response.data.success) {
      // console.log('/casi-web/permissions :   ', response.data.data)
      dispatch(createAction(ActionTypes.GET_USER_PERMISSION)(response.data.data))
    }
  }
}

export const getPermissions = () => {
  return async dispatch => {
    const response = await request.get('/casi-web/permissions')
    if (response && response.data && response.data.success) {
      dispatch(createAction(ActionTypes.GET_PERMISSION)(response.data.data))
    }
  }
}

export const triggerAuthentication = status => {
  return dispatch => {
    dispatch(createAction(ActionTypes.RESET_AUTH)(status))
  }
}
