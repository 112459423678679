import React, { Component } from 'react'
import { Table } from 'antd'
class ExpectedDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: []
    }
  }
  getColumns = () => {
    return [
      {
        title: 'Source Service',
        dataIndex: 'sourceService',
        key: 'sourceService',
        align: 'center'
      },
      {
        title: 'UUID',
        dataIndex: 'uuid',
        key: 'uuid',
        align: 'center'
      },
      {
        title: 'SIM Creation Time',
        dataIndex: 'simCreateTime',
        key: 'simCreateTime',
        align: 'center'
      },
      {
        title: 'Casi Received Time',
        dataIndex: 'casiReceivedTime',
        key: 'casiReceivedTime',
        align: 'center'
      }
    ]
  }

  componentDidMount() {
    if (this.props.viewRecord) {
      const viewRecord = this.props.viewRecord
      this.setState({
        detail: [
          {
            sourceService: 'Shipment Service',
            uuid: viewRecord.shipmentNoticeId,
            simCreateTime: viewRecord.shipmentNoticeSimCreationDate,
            casiReceivedTime: viewRecord.shipmentNoticeCasiReceivedTime
          },
          {
            sourceService: 'Delivery Service',
            uuid: viewRecord.deliveryId,
            simCreateTime: viewRecord.deliverySimCreationDate,
            casiReceivedTime: viewRecord.deliveryCasiReceivedTime
          },
          {
            sourceService: 'Carton Service',
            uuid: viewRecord.cartonId,
            simCreateTime: viewRecord.cartonSimCreationDate,
            casiReceivedTime: viewRecord.cartonCasiReceivedTime
          },
          {
            sourceService: 'Carton Item Service',
            uuid: viewRecord.cartonItemId,
            simCreateTime: viewRecord.cartonItemSimCreationDate,
            casiReceivedTime: viewRecord.cartonItemCasiReceivedTime
          }
        ]
      })
    }
  }

  render() {
    // console.log(this.props.viewRecord)
    return (
      <div className="content-container" style={{ margin: '0' }}>
        <div className="table-container" style={{ height: '300px', margin: '0', padding: '0' }}>
          <Table
            size="middle"
            columns={this.getColumns()}
            pagination={false}
            // pagination={{
            //   current: this.state.pageNum,
            //   pageSize: this.state.pageSize,
            //   size: 'small',
            //   pageSizeOptions: ['10', '20', '30', '40'],
            //   //   total: this.props.refList.length,
            //   //   showTotal: total => `Total ${total} items`,
            //   showSizeChanger: true
            // }}
            dataSource={this.state.detail.map((refs, key) => {
              return { ...refs, key }
            })}
          ></Table>
        </div>
      </div>
    )
  }
}

export default ExpectedDetail
