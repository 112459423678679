import React, { useEffect, useRef } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import * as OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import './Login.less'
import config from '../../oktaConfig'

const Login = () => {
  const { oktaAuth } = useOktaAuth()
  const widgetRef = useRef()

  useEffect(() => {
    if (!widgetRef.current) {
      return false
    }
    // console.log(config.oidc.clientId)
    const { issuer, clientId, redirectUri, scopes } = config.oidc

    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: require('../../assets/images/logo.jpg'),
      language: 'en',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to Nike',
          'primaryauth.username.placeholder': 'Email',
          'error.username.required': 'Please enter your email'
        }
      },
      authParams: {
        issuer,
        scopes
      }
    })

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
        scopes
      })
      .then(tokens => {
        // Add tokens to storage
        oktaAuth.handleLoginRedirect(tokens)
      })
      .catch(err => {
        // console.log('error : ', err)
        throw err
      })

    return () => widget.remove()
  }, [oktaAuth])

  return (
    <div className="login-contain">
      <div className="login-view" ref={widgetRef} />
    </div>
  )
}
export default Login
