import axios from 'axios'
import store from '../store'
import * as ActionCreators from '../store/actions'
import { message } from 'antd'
import { generateAuthHeader } from '../utils/authHelp'

let requstCounts = 0

const instance = axios.create({
  // baseURL: 'https://api-casistg.nike.com'
  baseURL: process.env.REACT_APP_API_URL + '/api-proxy',
  // baseURL: process.env.REACT_APP_API_URL
  withCredentials: true,
  timeout: 60000
})

// const whiteListReg = RegExp('/batches/[a-zA-Z0-9]{32}$')
// Add a request interceptor
instance.interceptors.request.use(
  async function(config) {
    // Do something before request is sent
    // Don't show Spin when searching split status
    // if (!(config.method === 'get' && whiteListReg.test(config.url))) {
    if (!config.headers['no-spin']) {
      store.dispatch(ActionCreators.setSpinLoading(true)) // show Spin Loading
      requstCounts++
    }
    const authHeaders = generateAuthHeader()
    config.headers = {
      ...config.headers,
      ...authHeaders
    }

    // console.log('authHeaders',authHeaders)
    // console.log('config.headers', config.headers)

    // 解决IE中网络请求相同url会从缓存中读取的问题，为每个请求增加一个_t参数
    config.headers['Cache-Control'] = 'no-cache'
    config.params = {
      ...config.params,
      _t: new Date().getTime()
    }
    return config
  },
  function(error) {
    // Do something with request error
    requstCounts = requstCounts - 1 < 0 ? 0 : requstCounts - 1
    requstCounts === 0 && store.dispatch(ActionCreators.setSpinLoading(false))
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    // Do something with response data
    // if(response.config.method !== 'get'){
    requstCounts = requstCounts - 1 < 0 ? 0 : requstCounts - 1
    requstCounts === 0 && store.dispatch(ActionCreators.setSpinLoading(false))
    // }
    if (response && response.data && !response.data.success) {
      if (response.data.message) {
        message.error(response.data.message)
      } else {
        message.error(response.data.description)
      }
      if (response.data.code === '102') {
        store.dispatch(ActionCreators.triggerAuthentication(true))
      }
    }
    return response
  },
  function(error) {
    // Do something with response error
    requstCounts = requstCounts - 1 < 0 ? 0 : requstCounts - 1
    requstCounts === 0 && store.dispatch(ActionCreators.setSpinLoading(false))
    const { response } = error
    if (response) {
      // console.log('response',response)
      switch (response.status) {
        case 401:
          message.error(response.data.message)
          store.dispatch(ActionCreators.triggerAuthentication(true))
          break
        default:
          message.error(response.data.message)
      }
    } else {
      console.log('api  error  :', error)
      message.error(error.message)
    }

    // return Promise.reject(error);
  }
)

export default instance
