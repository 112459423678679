/* @flow */
import React from 'react'
import { Link } from 'react-router-dom'

const Logo = ({ link, title }) => (
  <div>
    <Link to={link}>
      <span style={{ color: '#FFF', fontSize: '60px' }} className="nsg-glyph--swoosh" />
      &nbsp;&nbsp;
      <span style={{ color: '#FFF' }}>{title}</span>
    </Link>
  </div>
)

export default Logo
