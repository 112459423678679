import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as ActionCreators from '../../../store/actions'
import { Row, Col, Select, Button, Table, Divider, Modal, message } from 'antd'
import moment from 'moment'
import { navigate } from '../../../utils/miscUtil'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import memoize from 'memoize-one'
import { updatableRoles } from '../../../utils/permission'

const { Option } = Select

class Role extends Component {
  constructor(props) {
    super(props)
    this.navigate = navigate.bind(this)
    this.state = {
      visible: false,
      deleteRecord: {
        role: ''
      },
      criteria: {
        pageNum: 1,
        pageSize: 10,
        id: ''
      }
    }
  }
  getColumns = memoize((permissions = []) => [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 150,
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 200
    },
    {
      title: 'User Number',
      dataIndex: 'userNumber',
      key: 'userNumber',
      width: 200,
      render: (userNumber, record) => <Link to={`/user/user?roleId=${record.id}`}>{userNumber}</Link>
    },
    {
      title: 'Access',
      dataIndex: 'permissions',
      key: 'permissions',
      width: 200,
      render: rolePermissions =>
        permissions.length > 0 &&
        (_.intersection(rolePermissions, permissions).length === permissions.length ? 'Full' : 'Partial')
    },
    {
      title: 'Create Date',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 200,
      render: text => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '')
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      // width: 160,
      render: (text, record) =>
        this.canUpdateRole(record.id) && (
          <span>
            <a className="nike-link" onClick={() => this.navigate(`/user/role/${record.id}`)}>
              Update
            </a>
            <Divider type="vertical" />
            <a className="nike-link" onClick={() => this.deleteUserRecord(record)}>
              Delete
            </a>
          </span>
        )
    }
  ])

  canUpdateRole(roleId) {
    return updatableRoles(this.props.permission, this.props.roles).some(role => role.id === roleId)
  }

  handleTableChange = (pagination, filters, sorter) => {
    const params = {
      ...this.state.criteria,
      pageNum: pagination.current,
      pageSize: pagination.pageSize
    }
    this.setState({ criteria: { ...params } })
    this.props.getRoleList(params)
  }
  async deleteRole(role) {
    const result = await this.props.deleteRole(role.id)
    if (result) {
      this.setState({ visible: false })
      message.success('Role deleted successfully.')
      this.props.getRoleList(this.state.criteria)
    }
  }

  deleteUserRecord = record => {
    this.setState({ visible: true, deleteRecord: record })
  }
  searchClickEvent = () => {
    this.props.getRoleList(this.state.criteria)
  }
  createClickEvent = () => {
    this.navigate('/user/role/add')
  }
  componentDidMount() {
    this.props.getRoles()
    this.props.getRoleList(this.state.criteria)
  }
  render() {
    const permissionCodes = _.uniq(this.props.sysPermission.map(p => p.code))
    const columns = this.getColumns(permissionCodes.filter(p => p !== 'super-permission'))
    return (
      <div className="content-container">
        <Row className="search-bar" type="flex" justify="space-between" align="middle">
          <Col span={12}>
            <Row type="flex" align="middle">
              <Col className="search-title" style={{ textAlign: 'right', paddingRight: '8px' }} span={9}>
                Role&nbsp;&nbsp;
              </Col>
              <Col span={10} className="text-left">
                <Select
                  style={{ width: 200 }}
                  allowClear={true}
                  value={this.state.criteria.id}
                  onChange={value =>
                    this.setState({
                      criteria: { ...this.state.criteria, id: value }
                    })
                  }
                >
                  {this.props.roles &&
                    this.props.roles.map(role => (
                      <Option key={role.id} value={role.id}>
                        {role.roleName}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="text-center">
            <Row>
              <Col span={10} className="text-right">
                <Button type="primary" onClick={this.searchClickEvent}>
                  Search
                </Button>
              </Col>
              <Col span={10} className="text-left" style={{ paddingLeft: '10px' }}>
                <Button type="primary" onClick={this.createClickEvent}>
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="table-container">
          <Table
            size="middle"
            // bordered
            scroll={{ y: `calc(100vh - 316px)`, x: 1140 }}
            columns={columns}
            pagination={{
              current: this.state.criteria.pageNum,
              pageSize: this.state.criteria.pageSize,
              size: 'small',
              pageSizeOptions: ['10', '20', '30', '40'],
              total: this.props.roleList.total,
              showTotal: total => `Total ${total} items`,
              showSizeChanger: true
            }}
            dataSource={
              this.props.roleList.records &&
              this.props.roleList.records.map((role, key) => {
                return { ...role, key }
              })
            }
            onChange={this.handleTableChange}
          ></Table>
        </div>
        <Modal
          title={['Delete Role', `'${this.state.deleteRecord.roleName}'`].join(' - ')}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          cancelText="CLOSE"
          onOk={() => this.deleteRole(this.state.deleteRecord)}
          okText="DELETE"
        >
          <h2 style={{ fontSize: 'larger', color: 'red', textAlign: 'center' }}>Reminder!</h2>
          <span>
            Delete Role will automatically suspend all access of relevant users enrolled in this role group, still wish
            to continue?
          </span>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ common: { permission, sysPermission }, roles: { roles, roleList } }) => {
  return { permission, roles, roleList, sysPermission }
}

const mapDispatchToProps = dispatch => {
  return {
    getRoles: () => dispatch(ActionCreators.getRoles()),
    getRoleList: params => dispatch(ActionCreators.getRoleList(params)),
    deleteRole: id => dispatch(ActionCreators.deleteRole(id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Role)
