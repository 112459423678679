import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState, Suspense } from 'react'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  setUserInfo,
  getUserPermissions,
  triggerAuthentication,
  setRouterConfig,
  getPermissions
} from '../store/actions'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import { Layout, Spin, Menu, Row, Col, Icon, Breadcrumb } from 'antd'
import {
  getRouterConfig,
  hasVisibleChildren,
  getSubMenuPath,
  checkPermissionWithCurrentPath
} from '../router/routerConfig.js'
import Header from './header/Header'
import UnAuthorized from '../components/security/UnAuthorized'
import '../App.less'
import _ from 'lodash'

const { Content, Sider } = Layout
const { SubMenu } = Menu

const concatPath = (parentPath, path) => {
  return parentPath && parentPath !== '/' ? [parentPath, path].join('') : path
}

const renderMenuItem = (item, parentPath) => {
  if (hasVisibleChildren(item)) {
    return (
      !item.hidden && (
        <SubMenu
          mode="inline"
          key={concatPath(parentPath, item.path)}
          title={
            <span>
              {item.icon && <Icon type={item.icon} />}
              <span>{item.title}</span>
            </span>
          }
        >
          {item.children.map(subMenuItem => renderMenuItem(subMenuItem, concatPath(parentPath, item.path)))}
        </SubMenu>
      )
    )
  } else {
    return (
      !item.hidden && (
        <Menu.Item key={concatPath(parentPath, item.path)}>
          <Link to={concatPath(parentPath, item.path)}>
            <span style={{ lineHeight: '20px' }}>{item.title}</span>
          </Link>
        </Menu.Item>
      )
    )
  }
}

const MainLayout = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { pathname } = useLocation()
  const isResetAuth = useSelector(state => state.common.isResetAuth)
  const spinLoading = useSelector(state => state.common.spinLoading)
  const permission = useSelector(state => state.common.permission)
  const routerConfig = useSelector(state => state.common.routerConfig)
  // const [currentPermission,setCurrentPermission] = useState([])
  const [isLogin, setIsLogin] = useState(false)
  const { authState, oktaAuth } = useOktaAuth()
  // const [routerConfig, setRouterConfig] = useState([])
  const [openKeys, setOpenKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  // const [submenuKeys, setSubmenuKeys] = useState(
  //   ['/user/user', '/user/role', '/order/actual', '/order/expected', '/reference/csc']
  // )
  useEffect(() => {
    if (!isLogin) {
      if (!localStorage.getItem('okta-token-storage') || localStorage.getItem('okta-token-storage') == '{}') {
        history.push('/login')
        setIsLogin(true)
      }
    }
  }, [isLogin])

  // 若 token 失效，进行登录
  useEffect(() => {
    if (isResetAuth) {
      history.push('/login')
      dispatch(triggerAuthentication(false))
    }
  }, [isResetAuth])

  // 初始化授权 获取用户信息
  useEffect(() => {
    if (!authState.isAuthenticated) {
      dispatch(setUserInfo(null))
    } else {
      dispatch(getUserPermissions())
      dispatch(getPermissions())
      oktaAuth.getUser().then(info => {
        dispatch(setUserInfo(info))
      })
    }
  }, [authState, oktaAuth])

  // 根据获取的 permission 配置路由
  useEffect(() => {
    if (permission) {
      // setCurrentPermission(permission)
      let peemissionRouter = getRouterConfig(permission)
      // setRouterConfig(peemissionRouter)
      dispatch(setRouterConfig(peemissionRouter))
      if (routerConfig.length > 0) {
        if (checkPermissionWithCurrentPath(history.location.pathname, routerConfig)) {
          //
          // console.log('检查通过')
        } else {
          history.push('/unAuthorized')
          // console.log('检查失败')
        }
      }
    }
  }, [permission])

  const loggout = async () => {
    await oktaAuth.signOut()
  }

  const onOpenChange = openKeys => {
    setOpenKeys(openKeys)
  }

  // RouterChange
  useEffect(() => {
    if (!localStorage.getItem('okta-token-storage') || localStorage.getItem('okta-token-storage') == '{}') {
      history.push('/login')
    } else {
      getSubMenu(history.location.pathname)
    }
  }, [pathname])

  const getSubMenu = locationPath => {
    const submenuKeys = getSubMenuPath()
    const targeSubMenuKey = locationPath.substring(0, locationPath.lastIndexOf('/'))
    if (targeSubMenuKey.length === 0) {
      setOpenKeys([locationPath])
      setSelectedKeys([locationPath])
    } else {
      if (targeSubMenuKey.split('/').length == 2) {
        const matchedSubMenuKey = submenuKeys.find(key => locationPath === key)
        setOpenKeys([targeSubMenuKey])
        setSelectedKeys([targeSubMenuKey, matchedSubMenuKey])
      } else if (targeSubMenuKey.split('/').length == 3) {
        const pathes = targeSubMenuKey.split('/')
        const onelevel = '/' + pathes[1]
        const twolevel = '/' + pathes[1] + '/' + pathes[2]
        setOpenKeys([onelevel])
        setSelectedKeys([onelevel, twolevel])
      }
    }
  }

  const generateRoute = (basePath = '', routerConfig = []) => {
    if (routerConfig.length) {
      return _.flatten(
        routerConfig.map(config =>
          _.concat(
            generateRoute(concatPath(basePath, config.path), config.children),
            config.component ? (
              <Route
                key={concatPath(basePath, config.path)}
                path={concatPath(basePath, config.path)}
                component={config.component}
              />
            ) : (
              []
            )
          )
        )
      )
    }
  }

  const renderBreadcrumbItem = (url, targetMenuItem, isCurrent) => {
    return (
      <Breadcrumb.Item key={url}>
        {hasVisibleChildren(targetMenuItem) || isCurrent ? (
          targetMenuItem.title
        ) : (
          <Link to={url}>{targetMenuItem.title}</Link>
        )}
      </Breadcrumb.Item>
    )
  }

  const renderBreadcrumbItems = (routerConfig, locationPath, matchPath) => {
    const pathSnippets =
      matchPath &&
      locationPath
        .substring(locationPath.indexOf(matchPath) + matchPath.length)
        .split('/')
        .filter(path => path)
    let searchingMenuItems = routerConfig
    return (
      pathSnippets &&
      pathSnippets
        .map((path, index) => {
          const url = concatPath(matchPath, `/${pathSnippets.slice(0, index + 1).join('/')}`)
          const targetMenuItem = searchingMenuItems.find(
            menuItem => menuItem.path === '/' + path || menuItem.path.startsWith('/:')
          )
          if (targetMenuItem) {
            const breadcrumbItem = renderBreadcrumbItem(
              url,
              targetMenuItem,
              url === concatPath(matchPath, locationPath)
            )
            searchingMenuItems = targetMenuItem.children || []
            return breadcrumbItem
          } else {
            searchingMenuItems = []
            return null
          }
        })
        .filter(item => item != null)
    )
  }

  return (
    <Layout>
      <Spin spinning={spinLoading} size="large" style={{ top: '50vh', transform: 'translateY(-50%)' }}>
        <Header authenticated={true} loggout={loggout} />
        <Layout style={{ height: 'calc(100vh - 61px)' }}>
          <Sider
            style={{
              height: 'calc(100vh - 61px)',
              position: 'fixed',
              left: 0,
              overflow: 'auto'
            }}
          >
            <Menu
              theme="dark"
              mode="inline"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onOpenChange={onOpenChange}
            >
              {routerConfig.map(item => renderMenuItem(item, match.path))}
              {/* {routerConfig.map(item => renderMenuItem(item, this.props.match.path))} */}
            </Menu>
          </Sider>
          <Layout style={{ marginLeft: 200, height: 'calc(100vh - 61px)', background: 'white' }}>
            <Row
              className="breadcrumb-container shadow-box"
              type="flex"
              align="middle"
              style={{ background: 'white', padding: '16px 10px' }}
            >
              <Col span={10}>
                <Breadcrumb>
                  <Breadcrumb.Item>SIM Integration</Breadcrumb.Item>
                  {renderBreadcrumbItems(routerConfig, history.location.pathname, match.path)}
                </Breadcrumb>
              </Col>
            </Row>
            <Content
              style={{
                // margin: '10px 0px',
                overflow: 'auto',
                background: '#F4F4F4'
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Redirect exact from="/" to="/order/actual"></Redirect>
                  {generateRoute(match.path, routerConfig)}
                  <Route key="/unAuthorized" path="/unAuthorized" component={UnAuthorized}></Route>
                </Switch>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </Layout>
  )
}
export default MainLayout
