import React from 'react'

const UnAuthorized = () => (
  <div className="content-container" style={{ display: 'flex', alignItems: 'top', justifyContent: 'center' }}>
    <div style={{ fontSize: '22px', textAlign: 'center', padding: '100px' }}>
      <p style={{ marginBottom: '10px', fontSize: '25px', color: 'red' }}>Sorry!</p>
      <p style={{ marginBottom: '10px' }}>You are not authorized to access this page.</p>
      <p>Please contact administrator.</p>
    </div>
  </div>
)

export default UnAuthorized
