import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
const initialState = {
  userList: {}
}
const userReducer = handleActions(
  {
    [ActionTypes.GET_USERLIST]: (state, action) => ({ ...state, userList: action.payload })
  },
  initialState
)

export { userReducer }
