import * as ActionTypes from '../actions/actionTypes'
import { handleActions } from 'redux-actions'
const initialState = {
  expectedList: [],
  expectStatistics: {},
  locationList: {}
}
const expectedReducer = handleActions(
  {
    [ActionTypes.GET_EXPECTEDLLIST]: (state, action) => ({ ...state, expectedList: action.payload }),
    [ActionTypes.GET_EXPECTSTATISTICS]: (state, action) => ({ ...state, expectStatistics: action.payload }),
    [ActionTypes.GET_LOCATIONLIST]: (state, action) => ({ ...state, locationList: action.payload })
  },
  initialState
)

export { expectedReducer }
